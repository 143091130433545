// extracted by mini-css-extract-plugin
export var container = "ProfileAccountInformationPage-module--container--Myf4m";
export var content = "ProfileAccountInformationPage-module--content--vfbgl";
export var title = "ProfileAccountInformationPage-module--title--3RSf7";
export var goBackIcon = "ProfileAccountInformationPage-module--goBackIcon--3b0_w";
export var text = "ProfileAccountInformationPage-module--text--Sdeuf";
export var lastChanged = "ProfileAccountInformationPage-module--lastChanged--1HlDV";
export var btnContainer = "ProfileAccountInformationPage-module--btnContainer--1s6M_";
export var editBtn = "ProfileAccountInformationPage-module--editBtn--laoP3";
export var saveBtn = "ProfileAccountInformationPage-module--saveBtn--1K7Ai";
export var row = "ProfileAccountInformationPage-module--row--tZ2l7";
export var inputContainer = "ProfileAccountInformationPage-module--inputContainer--19nOI";
export var label = "ProfileAccountInformationPage-module--label--27Ry0";
export var button = "ProfileAccountInformationPage-module--button--3D8_y";
export var footerContainer = "ProfileAccountInformationPage-module--footerContainer--3lamy";