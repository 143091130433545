import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { trackingGA } from '@merchx-v2/helpers'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { navigate } from 'utils/navigate'
import { Button, Footer, Header, Input, Notification, SelectWithLabel, SEO } from 'components'
import goBackIcon from 'assets/goBack.svg'

import { actionCreators as actionLoginCreators, selectors as selectorsLogin } from 'state/LoginPage/reducer'
import { updateCustomerAccount, isZipCodeValid } from 'state/LoginPage/actions'
import { actionCreators as actionHomeCreators, selectMenu } from 'state/HomePage/reducer'

import * as styles from './ProfileAccountInformationPage.module.scss'

const ProfileAccountInformationPage = ({
  menu,
  resetMenuFilters,
  setMenuCollection,
  loginState,
  setReduxFirstName,
  setReduxLastName,
  setReduxAddress,
  setReduxAddress2,
  setReduxCity,
  setReduxState,
  setReduxZipCode,

  updateCustomerAccount
}) => {
  const { landing } = useSiteMetadata()

  const countries = landing.COUNTRIES

  const countriesRegions = {}

  const countriesOptions = Object.values(countries).map(country => {
    countriesRegions[country.id] = country.regions.map(region => ({
      value: region.id,
      label: region.name,
      autocomplete: region.iso2
    }))

    return {
      value: country.id,
      label: country.name,
      autocomplete: country.name
    }
  })

  const [notificationTitle, setNotificationTitle] = useState('Error')
  const [notification, showNotification] = useState()
  const [isShowErrors, setIsShowErrors] = useState(false)

  const [firstName, setFirstName] = useState(loginState.firstName)
  const [lastName, setLastName] = useState(loginState.lastName)
  const [address, setAddress] = useState(loginState.address)
  const [address2, setAddress2] = useState(loginState.address2)
  const [city, setCity] = useState(loginState.city)
  const [state, setState] = useState(loginState.state)
  const [zipCode, setZipCode] = useState(loginState.zipCode)

  const regionsOptions = countriesRegions[countriesOptions[0].value]

  useEffect(() => {
    window.scrollTo(0, 0)
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setNotificationTitle('Error')
    showNotification(loginState.UIState.error)
  }, [loginState.UIState.error])

  useEffect(() => {
    if (!firstName && loginState.firstName !== '') {
      setFirstName(loginState.firstName)
    }
    if (!lastName && loginState.lastName !== '') {
      setLastName(loginState.lastName)
    }
    if (!address && loginState.address !== '') {
      setAddress(loginState.address)
    }
    if (!address2 && loginState.address2 !== '') {
      setAddress2(loginState.address2)
    }
    if (!city && loginState.city !== '') {
      setCity(loginState.city)
    }
    if (!state && loginState.state !== '') {
      setState(loginState.state)
    }
    if (!zipCode && loginState.zipCode !== '') {
      setZipCode(loginState.zipCode)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState.firstName, loginState.lastName, loginState.address, loginState.address2, loginState.city, loginState.state, loginState.zipCode])

  const handleSubmit = async e => {
    e.preventDefault()
    setIsShowErrors(true)
    const { type: updateAccountTaskType } = await updateCustomerAccount({
      firstName,
      lastName,
      address,
      address2,
      city,
      state: typeof state === 'object' ? state.label : state,
      zipCode
    })

    if (updateAccountTaskType === 'login/updateCustomerAccount/fulfilled') {
      setReduxFirstName(firstName)
      setReduxLastName(lastName)
      setReduxAddress(address)
      setReduxAddress2(address2)
      setReduxCity(city)
      setReduxState(state)
      setReduxZipCode(zipCode)
      setNotificationTitle('Info')
      showNotification('Account information was updated successfully!')
    }
  }

  const handleCollectionClicked = collection => {
    setMenuCollection(collection)
  }

  const handleReset = () => {
    resetMenuFilters()
  }

  if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
    return null
  }

  const refreshToken = localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken')

  if (!refreshToken) {
    return null
  }

  return (
    <div className={styles.container}>
      <SEO pathname='/profile/account-information' />
      <Header menu={menu} onCollectionClicked={handleCollectionClicked} onResetCollection={handleReset} isNeedDescription={true} />
      <div className={styles.content}>
        <div className={styles.title}>
          <img className={styles.goBackIcon} src={goBackIcon} alt='<' onClick={() => navigate('/profile')} />
          Account Details
        </div>
        <div className={styles.row}>
          <div className={styles.inputContainer}>
            <div className={styles.label}>First Name*</div>
            <Input
              placeholder='First Name'
              onChange={setFirstName}
              isValid={firstName !== '' ? true : false}
              isShowErrors={isShowErrors}
              errorMessage='Please input your First Name'
              defaultValue={firstName}
              name='first-name'
              autoComplete='name first-name'
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.label}>Last Name*</div>
            <Input
              placeholder='Last Name'
              onChange={setLastName}
              isValid={lastName !== '' ? true : false}
              isShowErrors={isShowErrors}
              errorMessage='Please input your Last Name'
              defaultValue={lastName}
              name='last-name'
              autoComplete='name last-name'
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.inputContainer}>
            <div className={styles.label}>Address 1*</div>
            <Input
              placeholder='Address (please include Unit/Apt)'
              onChange={setAddress}
              isValid={address !== '' ? true : false}
              isShowErrors={isShowErrors}
              errorMessage='Please input your Address'
              defaultValue={address}
              name='street-address'
              autoComplete='shipping street-address'
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.inputContainer}>
            <div className={styles.label}>Address 2</div>
            <Input placeholder='Address 2' onChange={setAddress2} isShowErrors={false} errorMessage='Please input your Address 2' defaultValue={address2} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.inputContainer}>
            <div className={styles.label}>City*</div>
            <Input
              placeholder='City'
              onChange={setCity}
              isValid={city !== '' ? true : false}
              isShowErrors={isShowErrors}
              errorMessage='Please input your City'
              defaultValue={city}
              name='city'
              autoComplete='shipping address-level2'
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.inputContainer}>
            <div className={styles.label}>State*</div>
            <SelectWithLabel
              placeholder='Select State'
              onSelect={setState}
              isValid={state !== '' ? true : false}
              isShowErrors={isShowErrors}
              errorMessage='Please select your State'
              options={regionsOptions}
              value={state}
              inputProps={{
                name: 'state',
                autoComplete: 'shipping address-level1'
              }}
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.label}>Zip Code*</div>
            <Input
              placeholder='Zip Code'
              onChange={setZipCode}
              isValid={isZipCodeValid(zipCode).isValid}
              isShowErrors={isShowErrors}
              errorMessage={isZipCodeValid(zipCode).error}
              defaultValue={zipCode}
              name='postal-code'
              autoComplete='shipping postal-code'
            />
          </div>
        </div>

        <div className={styles.button}>
          <Button value='Save' loading={loginState.UIState.isLoading} className='buttonSmall' customAction={handleSubmit} />
        </div>
      </div>

      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
        <Notification
          title={notificationTitle}
          message={notification}
          onNotificationClosed={() => {
            setNotificationTitle('Error')
            showNotification('')
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  menu: selectMenu(state),
  loginState: selectorsLogin.selectLoginState(state)
})

const mapDispatchToProps = {
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
  setReduxFirstName: actionLoginCreators.setFirstName,
  setReduxLastName: actionLoginCreators.setLastName,
  setReduxAddress: actionLoginCreators.setAddress,
  setReduxAddress2: actionLoginCreators.setAddress2,
  setReduxCity: actionLoginCreators.setCity,
  setReduxState: actionLoginCreators.setState,
  setReduxZipCode: actionLoginCreators.setZipCode,
  updateCustomerAccount
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAccountInformationPage)
